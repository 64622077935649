<template>
    <div class='user'>
        用户
    </div>
</template>

<script>
export default {
    components: {},
    data () {
        // 这里存放数据
        return {
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 方法集合
    methods: {
    }
}
</script>
<style scoped>
</style>
